.login{
  width: 100%;
  height: 100vh;
  background: url('../../images/bg.jpg') no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_box{
  /* position: absolute;
  top: 4vh;
  left: 8%;
  width: 32%;
  height: 68%;
  padding-left: 100px;
  padding-top: 100px;
  border-radius: 10px;
  margin-top: 10vh;
  background-image: url('../../images/bg_form.png');
  background-repeat: no-repeat;
  background-size: 674px 605px;
  background-position: -4px 0px !important; */
  width: 1295px;
  height: 822px;
  background: #FFFFFF;
  border-radius: 12px;
  position: relative;
}
.left{
  padding: 94px 109px 151px 79px;
}
.login .ant-btn-primary{
  background: #F6494D;
  border-color: #F6494D;
}
.welcome_text{
  font-size: 30px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #333;
  line-height: 30px;
}
.welcome{
  font-size: 30px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #333;
  line-height: 82px;
  margin-bottom: 30px;
}
.subimt_btn{
  border: none;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
  width: 240px;
  height: 72px;
  background: #306AEC !important;
  box-shadow: 0px 6px 12px 0px rgba(57,99,255,0.37);
  border-radius: 36px;
  margin-top: 70px;
}
.mb80{
  margin-bottom: 8vh;
}
.form_box .ant-input{
  border: none;
  width: 528px;
  height: 62px;
  background: #F5F6F7;
  border-radius: 12px;
  border-radius: 0;
  box-shadow: none !important;
}
.form_box  .ant-input-affix-wrapper{
  border: none;
  width: 528px;
  height: 62px;
  background: #F5F6F7;
  border-radius: 12px;
  border-radius: 0;
  box-shadow: none !important;
}
.right{
  width: 579px;
  height: 822px;
  border-radius: 0px 12px 12px 0px;
  background-image: url('../../images/login.png');
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
}